<template>
  <v-card class="changeCard pt-1">
    <v-alert v-for="({ text, color, icon }, index) in preMessages" :key="'pre-message-'+index" :color="color" :colored-border="true" border="left" elevation="2" class="ma-2" light>
      <template v-slot:prepend>
        <v-icon :color="color" style="margin-right: 16px;width:27px;text-align:center;">{{ icon }}</v-icon>
      </template>
      {{ text }}
    </v-alert>
    <v-card-text>
      <p>Click the pencil icon next to a course to drop it or change the grade mode to pass/fail (only available for upper classmen), or to cancel a change.</p>
      <v-row>
        <v-col :cols="12" md="10" lg="8" xl="6">
          <v-card>
            <v-list>
              <course-entry v-for="(item, index) in mySchedule" :key="item.crn" :item="item" :changes="changes" :can-change-grade-mode="isUpperClassman" :can-drop="allowDrop" @drop="dropClass(item, index)" @change="changeGradeMode(item, index)" @undo="undoChange(item, index)"></course-entry>
            </v-list>
            <v-toolbar flat>
              <add-class-dialog v-if="allowAdd" :term="term" :is-upper-classman="isUpperClassman" :schedule="mySchedule" @add="addCourse"></add-class-dialog>
              <v-spacer></v-spacer>
              <v-subheader v-if="!isXSmall">Credits: {{ totalCredits }}</v-subheader>
            </v-toolbar>
            <v-subheader v-if="isXSmall">Credits: {{ totalCredits }}</v-subheader>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-alert v-if="isChanged && totalCredits < 11 && ['05', '60'].includes(term.substring(4))" type="error" border="left" elevation="2" class="ma-2 mb-6" colored-border>You currently have selected fewer than 12 credit hours, which will change your status to part time. This may affect your housing selection, athletic eligibility, and any financial aid you are currently receiving.</v-alert>
    <v-alert v-else-if="isChanged && totalCredits > 18" type="error" border="left" elevation="2" class="ma-2 mb-6" colored-border>Your chosen changes will cause you to be over full-time. This may result in additional charges to your account. You will need to consult with the Records office before the changes will be finalized.</v-alert>
    <v-card>
      <v-card-text>
        <v-checkbox v-if="isChanged && ((totalCredits < 11 && ['05', '60'].includes(term.substring(4))) || totalCredits > 18)" v-model="verifyStudentChange" label="I understand that, upon approval, my student status will change." class="mt-0"></v-checkbox>
        <p>Please explain in the comment box below your reason for the schedule change or any other comments you may have.</p>
        <v-textarea v-model="comments" label="Reason/Comments" outlined hide-details></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isChanged || comments === ''" color="info" @click="submitChanges">Submit Changes</v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>
<style>
.theme--light .changeCard {
  background-color: #dcdcdc
}
.theme--dark .changeCard {
  background-color: #121212
}
</style>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {
    CourseEntry: () => import('./courseEntry'),
    AddClassDialog: () => import('./addClassDialog')
  },
  props: {
    term: {
      type: String,
      required: true
    },
    schedule: {
      type: Array,
      required: true
    },
    messages: {
      type: Array,
      required: true
    },
    academics: {
      type: Object,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const preMessages = ref([])
    const isUpperClassman = computed(() => ['JR', 'SR'].includes(props.academics.classLevel))
    const allowAdd = ref(false)
    const allowDrop = ref(false)
    const reqApproval = ref(true)
    const changes = ref([])
    const origChanges = ref([])

    const mySchedule = ref([])
    watch(() => props.schedule, () => {
      mySchedule.value = [ ...props.schedule ]
    })
    const totalCredits = computed(() => {
      const dropCrns = changes.value.filter(({ action }) => action === 'drop').map(({ crn }) => crn)
      const courses = mySchedule.value.filter(({ crn, regStatus }) => !['DC', 'DD'].includes(regStatus) && !dropCrns.includes(crn))
      return courses.reduce((prev, { credits }) => prev + credits, 0)
    })

    watch(() => props.messages, () => {
      const now = new Date()
      for (const { effDate, messages, add, drop, reqAppr } of props.messages) {
        const dt = new Date(effDate)
        if (dt > now) break
        preMessages.value = messages
        allowAdd.value = add
        allowDrop.value = drop
        if (reqAppr != null) reqApproval.value = reqAppr
      }
    })

    function dropClass (item) {
      const index = changes.value.findIndex(({ crn }) => item.crn === crn)
      const obj = { action: 'drop', crn: item.crn, status: 'pending' }
      if (index >= 0) {
        changes.value.splice(index, 1, obj)
      } else {
        changes.value.push(obj)
      }
    }
    function changeGradeMode (item) {
      const index = changes.value.findIndex(({ crn }) => item.crn === crn)
      const obj = { action: 'change', crn: item.crn, status: 'pending', gradeMode: 'pass-fail' }
      if (index >= 0) {
        changes.value.splice(index, 1, obj)
      } else {
        changes.value.push(obj)
      }
    }
    function undoChange (item) {
      const index = changes.value.findIndex(({ crn }) => item.crn === crn)
      if (changes.value[index].status !== 'pending') {
        // Push it onto the timeline that this was cancelled by the student
        console.log('Add cancellation to the timeline')
      }
      if (changes.value[index].action === 'add') {
        console.log('action is to add; looking for item in the schedule')
        const schedInd = mySchedule.value.findIndex(({ crn }) => crn === item.crn)
        if (schedInd >= 0) mySchedule.value.splice(schedInd, 1)
      }
      changes.value.splice(index, 1)
    }

    async function addCourse ({ id, passFail }) {
      // console.log({ id, passFail })
      const course = await root.$feathers.service('calendar/classes').get(id)
      const { data } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: course.instructors.map(({ pidm }) => pidm) } } })
      if (course) {
        // console.log(course)
        const meets = course.meetingBase.map(({ days, startTime, endTime, room, building }) => {
          let str = ''
          if (days) str = days
          if (startTime && endTime) str += ' ' + startTime + ' - ' + endTime
          if (room && building) str += ' in ' + room + ' (' + building + ')'
          return str
        }).join('<br/>')
        mySchedule.value.push({ _id: id, credits: course.credits, crn: course.crn, gradeMode: passFail ? 'pass-fail' : 'grade', instructor: data[0].displayName, meetingBase: course.meetingBase, meets, regStatus: '', title: course.title })
        changes.value.push({ action: 'add', crn: course.crn, status: 'pending' })
      }
    }

    const isChanged = computed(() => JSON.stringify(changes.value) !== JSON.stringify(origChanges.value))
    const service = root.$feathers.service('student/schedule-change')
    const changeId = ref('')
    watch(() => props.term, async () => {
      const { data } = await service.find({ query: { pidm: user.value.pidm, term: props.term } })
      if (data.length > 0) {
        changes.value = [ ...data[0].changes ]
        const toAdd = []
        for (const { crn, gradeMode } of changes.value) {
          if (mySchedule.value.filter(({ crn: schedCrn }) => crn === schedCrn).length === 0) {
            // Include any courses which have been added (they are in the changes but not in the schedule)
            const { data: classData } = await root.$feathers.service('calendar/classes').find({ query: { crn, term: props.term } })
            toAdd.push({ id: classData[0]._id, passFail: gradeMode ? gradeMode === 'pass-fail' : false })
          }
        }
        origChanges.value = [ ...data[0].changes ]
        changeId.value = data[0]._id
        for (const row of toAdd) addCourse(row)
      }
    })

    const verifyStudentChange = ref(false)
    const comments = ref('')
    async function submitChanges () {
      const obj = { changes: [] }
      for (const row of changes.value) {
        let status = 'submitted'
        const approvals = []
        if (reqApproval.value) {
          // Instructor
          const { data } = await root.$feathers.service('calendar/classes').find({ query: { term: props.term, crn: row.crn } })
          if (data.length > 0) {
            approvals.push({ category: 'Instructor', pidm: data[0].instructors[0].pidm })
          }
          // Advisor
          const rec = props.academics.advisor.find(({ code }) => code === 'MAJR')
          approvals.push({ category: 'Advisor', pidm: rec.pidm })
        } else {
          status = 'approved'
        }
        obj.changes.push({ ...row, submitted: new Date(), approvals, status })
      }
      const timeline = [
        {
          pidm: user.value.pidm,
          name: user.value.name,
          date: new Date(),
          text: 'Schedule Changes Submitted',
          icon: 'fal fa-file-plus',
          color: 'info',
          visibleToStudent: true
        },
        {
          pidm: user.value.pidm,
          name: user.value.name,
          date: new Date(),
          text: comments.value,
          icon: 'fal fa-comment',
          color: 'info',
          visibleToStudent: true
        }
      ]
      if (changeId.value) {
        obj.$push = { timeline }
        const temp = await service.patch(changeId.value, obj)
        changes.value = [ ...temp.changes ]
        origChanges.value = [ ...temp.changes ]
      } else {
        const temp = await service.create({
          term: props.term,
          pidm: user.value.pidm,
          bannerId: user.value.bannerId,
          name: user.value.name,
          status: 'submitted',
          changes: obj.changes,
          timeline
        })
        changeId.value = temp._id
        changes.value = temp.changes
        origChanges.value = temp.changes
      }
      let html = 'A schedule change was submitted by ' + user.value.name + ' and is ready for an initial review. You can <a href="https://portal.covenant.edu/student/schedule/' + changeId.value + '">access this request here.</a>'
      if (!reqApproval.value) {
        html.replace('for an initial review', 'to be processed (the system is set to not require approval right now)')
      }
      await root.$feathers.service('system/email').create({
        from: 'report-email@covenant.edu',
        to: 'recordsoffice@covenant.edu',
        subject: 'Schedule Change Submitted - ' + user.value.name,
        html
      })
      root.$store.dispatch('main/snackbar', { color: 'success', text: 'Changes saved successfully' })
      emit('changed')
    }

    const isXSmall = computed(() => root.$vuetify.breakpoint.name === 'xs')
    const isSmall = computed(() => ['xs', 'sm'].includes(root.$vuetify.breakpoint.name))

    return {
      user,
      preMessages,
      isUpperClassman,
      allowAdd,
      allowDrop,
      changes,
      origChanges,
      mySchedule,
      totalCredits,
      dropClass,
      changeGradeMode,
      undoChange,
      addCourse,
      isChanged,
      verifyStudentChange,
      comments,
      submitChanges,
      isXSmall,
      isSmall
    }
  }
}
</script>
